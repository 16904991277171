module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://polly.io","stripQueryString":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Polly™ Capital Markets Platform","short_name":"test","start_url":"/","background_color":"#663399","display":"minimal-ui","icon":"src/images/icon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"0c71d081000a1136b03606d141202f5a"},
    },{
      plugin: require('../node_modules/gatsby-plugin-gatsby-cloud/gatsby-browser.js'),
      options: {"plugins":[],"headers":{"/fonts/*":["Cache-Control: public, max-age=31536000, immutable"]}},
    },{
      plugin: require('../node_modules/@bond-london/gatsby-theme/gatsby-browser.ts'),
      options: {"plugins":[],"showDevPages":false,"isProduction":true,"projectName":"Polly™ Capital Markets Platform","useVideoCache":true,"videoCacheConnectionString":"DefaultEndpointsProtocol=https;AccountName=bondcache;AccountKey=V3vM2K9hSp1CWuueICom8jB2i5TiC6tisJLZUd6TCIaKMfXm5MR+rebcVLlgDUComIVTBp8ZXi1t+ASt7Uw9Sw==;EndpointSuffix=core.windows.net","videoWidth":1920,"graphCMSToken":"eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCIsImtpZCI6ImdjbXMtbWFpbi1wcm9kdWN0aW9uIn0.eyJ2ZXJzaW9uIjozLCJpYXQiOjE2NzM5Nzc1MTgsImF1ZCI6WyJodHRwczovL2FwaS1ldS13ZXN0LTIuaHlncmFwaC5jb20vdjIvY2xicDFzbm01MDJnZzAxdWs5ZmFtaG91cC9tYXN0ZXIiLCJtYW5hZ2VtZW50LW5leHQuZ3JhcGhjbXMuY29tIl0sImlzcyI6Imh0dHBzOi8vbWFuYWdlbWVudC5ncmFwaGNtcy5jb20vIiwic3ViIjoiODIxMDM4YWUtMmI5Ny00MTY1LTgwZWMtYWJlNzllZWU0NWNmIiwianRpIjoiY2xkMGl6cmljMDZqbzAxdXVneG53MXU3NCJ9.jFQcJug4SbZew_nGw7COQD2FuF3z6f4k4l7SoXoDDIUqU_cIUGfv_g7EbXyDlInPItquh34pN9laOrVkfsk25PbyPxu3kwlJG7gqV1yAGCG8H49TQ7tENVc0unfvu__xllAcAcc6jMrKOOZ21w4205mIiXnA1nMCzU0MUh6eQ_MblCSEsQdbve20pS6OJIe_30dtuqg951FT9Nd18VIcWsz4W1bNtfKG0dhRbHxbPtaHhTHYNDjqagDHcUqJH2LQ1P34TAylEf3vEgac9VZzPuFfO6k2wR-bvybV3gzEKKF7pyEUgUiqJVSP6HapoNGABwkONvS5-vtsO8TGo8NLs7X6lBp315VvAz21ZK3n6EjtD4Y4OuSp_ciCsnDN5IXsZW6Mi7g3GJoeSHWN14VdTcLBmxA8X_t4oaioBeEKdEp84Ui3sFMFXs_2Uazqix60FLE9KAZU5t6BJxBnlM5NDzaT8MgXgdcvsiTzkIl3KuErpy44vpu1tgWdDK7vL3p-M0H4QeH8iSKSjSg3j9VgbDa4x74Df1ITxRin7VIQG0ngq1f11Y9rJQU3YyZBPBxFMiG-BljJrlLHSRbvWqny38jMvYb5D6hheDNM34wnuOFxe4OwgJeYQKDK_fDU0tGyjoke6BfA_xfGwR3HX26obzAJrVx0vcv4OWwvGFh1YsU","graphCMSEndpoint":"https://api-eu-west-2.hygraph.com/v2/clbp1snm502gg01uk9famhoup/master","graphCMSStage":"PUBLISHED","enableEslint":false,"siteUrl":"https://polly.io","icon":"src/images/icon.png","allowIndex":true,"manifestOptions":{"name":"Polly™ Capital Markets Platform","short_name":"test","start_url":"/","background_color":"#663399","display":"minimal-ui","icon":"src/images/icon.png"}},
    },{
      plugin: require('../gatsby-browser.tsx'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
